import { fetchApi } from "@/utils/axios";

const prefix = '/api/dscloud-appservice-management'

/**
  * @description: 获取主页面配置list
  * @author: cxd
  * @time: 2024/5/26
 **/
export const getMainPageConfigList = (params) => {
    return fetchApi(prefix + '/web/homepage/list', params, 'POST', 'data')
}

/**
  * @description: 添加主页面配置
  * @author: cxd
  * @time: 2024/5/26
 **/
export const addMainPageConfig = (data) => {
    return fetchApi(prefix + '/web/homepage/add', data, 'POST', 'data')
}

/**
  * @description:
  * @author: cxd
  * @time: 2024/5/27
 **/
export  const updateMainPageConfig = (data) => {
    return fetchApi(prefix + '/web/homepage/update', data, 'PUT', 'data')
}

/**
  * @description: 查询原生页面
  * @author: cxd
  * @time: 2024/5/26
 **/
export const getAllNativePage = (penetrate) => {
    const params = {
        penetrate: penetrate
    }
    return fetchApi(prefix + '/web/native/homePage/list', params, 'GET', 'params')
}

/**
  * @description: 查询详情
  * @author: cxd
  * @time: 2024/5/27
 **/
export const getMainPageConfigDetail = (id) => {
    const params = {
        homePageId: id
    }
    return fetchApi(prefix + '/web/homepage/detail', params, 'GET', 'params')
}

/**
  * @description:
  * @author: cxd
  * @time: 2024/5/27
 **/
export const deleteMainPageConfigById = (id) => {
    return fetchApi(prefix + `/web/homepage/delete?homePageId=${id}`, {}, 'DELETE', 'params')
}


/**
  * @description: 启用/禁用
  * @author: cxd
  * @time: 2024/5/30
 **/
export const enableOrDisable = (params) => {
    return fetchApi(prefix + '/web/homepage/updateStatus', params, 'PUT', 'data')
}
